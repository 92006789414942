@font-face {
  font-family: "RalewayBlack";
  src: local("RalewayBlack"),
    url("./fuentes/Raleway-Black.ttf") format("truetype");
  font-weight: black;
}

@font-face {
  font-family: "RalewayBold";
  src: local("RalewayBold"),
    url("./fuentes/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RalewayMedium";
  src: local("RalewayMedium"),
    url("./fuentes/Raleway-Medium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "RalewayRegular";
  src: local("RalewayRegular"),
    url("./fuentes/Raleway-Regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "RalewaySemiBold";
  src: local("RalewaySemiBold"),
    url("./fuentes/Raleway-SemiBold.ttf") format("truetype");
  font-weight: semibold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


