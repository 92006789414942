@tailwind base;

@tailwind components;

@tailwind utilities;

.App {
  text-align: center;
  height: 100%;
  width : 100%
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-azul {
  background-color: #132C65;
}

.bg-rojo{
  background-color: rgba(238, 68, 82, 1)
}

.bg-footer{
  background-image : linear-gradient(270deg, #142A50 0%, #873851 52.6%, #EE4452 100%);
}

.bg-gradiente-subcargo {
  background-image: linear-gradient(135.47deg, rgba(20, 42, 80, 0.8) 46.01%, rgba(148, 72, 96, 0.8) 71.59%, rgba(238, 68, 82, 0.8) 94.58%);
}

.bg-gradiente-subcargo-horizontal {
  background-image: linear-gradient(270deg, #EE4452 0.02%, #8A465F 50.02%, #142A50 100.01%)
}

.bg-gradiente-rojo-azul{
  background-image: linear-gradient(90.51deg, #132C65 2.59%, #EE4452 105.24%);
}

.bg-calles2 {
  background-image: url("/src/Pages/imagenes/calles2.png")
}

.bg-calles2-movil {
  background-image: url("/src/Pages/imagenes/calles2.png");
  transform: scale(.5)
}

.bg-camion {
  background-image: url("/src/Pages/imagenes/camion_fondo.jpg")
}

.bg-camion2 {
  background-image: url("/src/Pages/imagenes/fondoDrawer.png");
}

.button-gradiente {
  font-family: "RalewayRegular";
  font-size: 24px;
  height: 69px;
  width: 329px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: linear-gradient(90.51deg, #132C65 2.59%, #EE4452 105.24%);
}

.font-face-rwBlack {
  font-family: "RalewayBlack";
}

.font-face-rwBold {
  font-family: "RalewayBold";
}

.font-face-rwMedium {
  font-family: "RalewayMedium";
}

.font-face-rwRegular {
  font-family: "RalewayRegular";
}

.font-face-rwSBold {
  font-family: "RalewaySemiBold";
}

.seccion-principal {
  height: 1080px
}
.seccion-secundaria {
  height: 1280px
}

.bg-gradiente-subcargo2 {
  background-image: linear-gradient(180deg, #142A50 0%, #D24152 48.44%, rgba(238, 68, 82, 0) 100%)
}

.slogan{
  width: 700.79px;
  height: 160.73px;
}

.text-subtitulo {
  font-family: "RalewayBold";
  font-size: 56px;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.text-azul {
  color: rgba(20, 42, 80, 1);
}

.text-azul-titulo {
  line-height: 75px;
  width: 834px;
  font-family: "RalewayBold";
  font-size: 48px;
  color: rgba(20, 42, 80, 1);
}

.text-negrita {
  font-family: "RalewayBold";
  font-size: 22px;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.text-normal {
  font-family: "RalewayRegular";
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.text-rojo{
  color:  rgba(238, 68, 82, 1)
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.zoom2 {
  transition: transform .2s;
}

.zoom2:hover {
  transform: scale(1.03);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


